import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/sirupy.jpg'
import styles from './post-grid.module.css'

const ChceteUdrzetVahuKrabickovouDietu = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={
          'Sirupy - jaký je mezi nimi rozdíl a čím sladit?  | NutritionPro'
        }
        description={
          'Různé druhy sirupů se dnes běžně využívají jako sladidla, ať už do nápojů, dezertů či běžných jídel. Dnešní výběr sirupů je skutečně rozmanitý, né všechny mají však stejné chuťové vlastnosti nebo složení. Někteří z vás se možná domnívají, že sirupy jsou lepší alternativou bílého cukru a že díky nim bude vylepšený pokrm také zdravější. To je však ve většině případů nepravda. V tomto článku se podíváme na několik druhů sirupů a řekneme si, z čeho se vlastně skládají a jestli je jejich používání vhodné na každodenní bázi.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/sirupy">
            Sirupy - jaký je mezi nimi rozdíl a čím sladit?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Sirupy - jaký je mezi nimi rozdíl a čím sladit? "
              date="17.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Různé druhy sirupů se dnes <b>běžně využívají jako sladidla</b>,
                ať už do nápojů, dezertů či běžných jídel. Dnešní výběr sirupů
                je skutečně <b>rozmanitý</b>, né všechny mají však{' '}
                <b>stejné chuťové vlastnosti nebo složení</b>. Někteří z vás se
                možná domnívají, že sirupy jsou{' '}
                <b>lepší alternativou bílého cukru</b> a že díky nim bude
                vylepšený pokrm také <b>zdravější</b>. To je však ve většině
                případů <b>nepravda</b>. V tomto článku se podíváme na několik
                druhů sirupů a řekneme si, <b>z čeho se vlastně skládají</b> a
                jestli je jejich používání vhodné na každodenní bázi.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="palačinky polité sirupem"
              />

              <p className={styles.postText}>
                <b>Sladká chuť </b> je jedna z prvních, kterou objevujeme už
                jako malé děti. Je proto logické, že k ní{' '}
                <b>v průběhu života tíhneme</b>. Je to jeden z důvodů, proč se
                neustále snažíme najít sladidla, která by byla{' '}
                <b>zdravá a bez kalorií</b>. V případě přírodních sirupů se však
                nedá mluvit o <b>zdravější variantě</b> a určitě nemůžeme říct,
                že by byly <b>nízkokalorické</b>. Ba naopak. Klasický bílý cukr
                a populární sladidla mají téměř <b>úplně stejné složení</b>. My
                si nyní představíme základ těchto sladidel.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Pomůže vám keto dieta zhubnout? To se dozvíte v{' '}
                  <a
                    href="https://nutritionpro.cz/blog/keto-dieta/"
                    target="_blank"
                  >
                    tomto článku
                  </a>
                  .
                </i>
              </p>

              <h5 className={styles.postTitle}>Glukóza (hroznový cukr)</h5>
              <p className={styles.postText}>
                Základem přírodních sirupů je především{' '}
                <b>glukóza a fruktóza</b> a většina těchto sladidel se skládá z
                jejich různého poměru či kombinace - <b>sacharózy</b>.
              </p>

              <p className={styles.postText}>
                Glukóza je zdroj <b>okamžitě dostupné energie</b>. Její{' '}
                <b>glykemický index je roven 100</b>, což je hodnota, od které
                se odvozuje určování glykemického indexu všech potravin. Kvůli
                tomu značně <b>rozhoupává hladinu krevního cukru</b>, který
                rychle vystřelí nahoru a zase rychle padá dolu, což je přesně
                to, co za normálních okolností nechceme.
              </p>

              <h5 className={styles.postTitle}>Fruktóza (ovocný cukr)</h5>
              <p className={styles.postText}>
                Glykemický index fruktózy je zhruba <b>5x nižší</b> než GI
                glukózy, to znamená, že se do krve uvolňuje{' '}
                <b>značně pomaleji</b>. Na svůj metabolismus{' '}
                <b>nepotřebuje hormon inzulin</b> (narozdíl od glukózy) a z
                velké části se sama odbourává v játrech. Především z těchto
                důvodu byla dříve považována za <b>ideální sladidlo</b> nejen
                pro běžnou populaci, ale především pro diabetiky. Dnes ovšem
                víme, že nahrazovat běžný cukr fruktózou{' '}
                <b>není vůbec moudrá volba</b>. Zvýšený příjem fruktózy totiž{' '}
                <b>zatěžuje játra</b> a přispívá k nealkoholické steatóze, tedy
                k jejich <b>ztučňování</b>. Navíc organismus zpracování fruktózy
                nereguluje, proto se při zvýšené konzumaci velmi snadno{' '}
                <b>ukládá ve formě tuku</b>. Fruktóza tedy{' '}
                <b>není vhodným sladidlem</b> ani pro diabetiky, ani pro nikoho
                jiného.
              </p>

              <p className={styles.postText}>
                <b>Nemusíte se však obávat fruktózy</b>, která se přirozeně
                vyskytuje <b>v ovoci</b>. To, kromě fruktózy, obsahuje spoustu{' '}
                <b>cenných látek</b>, jako jsou vitaminy, minerální látky,
                vláknina a fytochemikálie, pod které spadají například barviva
                či karotenoidy, což jsou zdraví prospěšné látky, které působí
                jako prevence určitých onemocnění.
              </p>

              <h5 className={styles.postTitle}>Sacharóza</h5>
              <p className={styles.postText}>
                Sacharóza je <b>kombinací glukózy a fruktózy</b>. Konkrétně 50 %
                tvoří glukóza a 50 % fruktóza. Sestává z ní například klasický{' '}
                <b>bílý (řepný) cukr</b>, ale také <b>třtinový cukr</b>. Pokud
                si myslíte, že je mezi nimi významný rozdíl, jste na omylu.
                Třtinový cukr má <b>téměř totožné složení</b>, jako cukr řepný.{' '}
                <b>Liší se pouze druhem rostliny</b>, ze které se získává. A jak
                jsou na tom se složením sirupy?
              </p>

              <h5 className={styles.postTitle}>Med</h5>
              <p className={styles.postText}>
                Nejprve se podíváme na klasický med. Ten je tvořen ze 40 %
                glukózou a 60 % fruktózou, to znamená, že jeho složení je téměř{' '}
                <b>identické</b>, jako <b>složení cukru</b>. Pokud jste
                zaslechli, že je med zdrojem zdraví prospěšných látek, jako jsou
                vitaminy a minerální látky, není tomu úplně tak. Med sice
                některé <b>mikronutrienty obsahuje</b>, ale v množství, které
                reálně sníme, jsou <b>v zanedbatelném množství</b>. Určitě ho
                proto neberte jako zdroj těchto látek ani jako zdravou náhradu
                cukru, neboť v tom, jestli si dáte med nebo klasický cukr,{' '}
                <b>není téměř žádný rozdíl</b>. Med ovšem <b>léčí povrchově</b>,
                pokud vás tedy bolí v krku, dejte si čaj s medem. Med však
                přidávejte až těsně předtím, než jej budete pít, kvůli teplotě
                čaje.
              </p>

              <h5 className={styles.postTitle}>Javorový sirup</h5>
              <p className={styles.postText}>
                Stejně jako řepný cukr, i javorový sirup sestává{' '}
                <b>ze sacharózy</b>, někdy je do něj přidána malá část glukózy
                či fruktózy. Pokud si tedy myslíte, že palačinky zalité
                javorovým sirupem patří mezi <b>zdravé svačinky</b>, není to tak
                úplně pravda. Je to vlastně stejné, jako byste si dali palačinky{' '}
                <b>s klasickým cukrem</b>.
              </p>

              <h5 className={styles.postTitle}>Datlový sirup</h5>
              <p className={styles.postText}>
                Tento sirup tvoří ze 100 % glukóza. Jedná se tedy o zdroj{' '}
                <b>okamžitě dostupné energie</b>, který je dobrý například pro{' '}
                <b>sportovce</b>. Čas od času neuškodí, ale jak už jsme si
                popsali u glukózy, významně rozhoupává hladinu krevního cukru,
                což není žádoucí. Stejně jako sirup, ani samotné datle nejsou
                zdravým mlsáním.
              </p>

              <h5 className={styles.postTitle}>Agáve sirup</h5>
              <p className={styles.postText}>
                Agáve sirup tvoří z <b>98 % fruktóza</b>. Jak je vám asi jasné,
                nadměrná konzumace tohoto sirupu nese{' '}
                <b>rizika nadměrné konzumace fruktózy</b>. Agáve sirup určitě
                není vhodný k pravidelnému slazení. Pokud ho máte rádi, volte ho
                pouze výjimečně.
              </p>

              <h5 className={styles.postTitle}>Čekankový sirup</h5>
              <p className={styles.postText}>
                Výjimkou mezi sirupy je čekankový sirup. Toto relativně nové
                sladidlo je <b>rozpustná vláknina z kořene čekanky</b>. První
                výhodou je, že jako vláknina působí <b>prebioticky</b>, to
                znamená, že <b>podporuje růst prospěšných bakterií</b> v tlustém
                střevě a napomáhá tak <b>správné funkci trávicího traktu</b>.
                Čekankový sirup má <b>nízký glykemický index</b> a ve srovnání s
                běžným cukrem obsahuje <b>málo kalorií</b>. Je proto{' '}
                <b>nejvhodnějším sladidlem</b> ze všech výše zmíněných. Je však
                možné, že si na tento sirup <b>budete muset zvyknout</b>, může
                vás <b>nadýmat</b>. Raději ho proto nejprve vyzkoušejte v klidu
                doma. Čekankový sirup je vhodné používat <b>za studena</b>, tedy
                do kaší, na palačinky, do jogurtu a tak dále. Dávejte však{' '}
                <b>pozor při pečení</b>. Pokud chcete nahrazovat cukr čekankovým
                sirupem, bude těsto, kvůli technologickým vlastnostem sirupu,
                tuhé a nebude nadýchané. Nikdy jej proto{' '}
                <b>nenahrazujte úplně</b>, maximálně z jedné třetiny.
              </p>

              <h5 className={styles.postTitle}>Cukr nebo sirup?</h5>
              <p className={styles.postText}>
                Klasický cukr, hnědý cukr i přírodní sirupy mají v podstatě{' '}
                <b>stejné složení</b>. Jedná se o <b>jednoduché cukry</b>, které
                by se v našem jídelníčku měly vyskytovat <b>minimálně</b>, a to
                i přesto, že jsou pro nás v určitých situacích <b>nezbytné</b>{' '}
                (při fyzické či psychické námaze). V jídelníčku jich máme
                poměrně dost a to proto, že se používají k doslazování různých
                potravin. Pokud tělo tuto rychle dostupnou energii nevyužije,
                ukládá se <b>do tukových zásob</b>. Množství vitaminů a
                minerálních látek je v přírodních sirupech minimální a v
                množství, které konzumujeme, zanedbatelné. Také mají obvykle{' '}
                <b>nízkou sladivost</b>, ale <b>mnohem vyšší cenu</b>, než
                klasický cukr. Pro dostatečné oslazení tedy musíte přidat mnohem
                více sladidla a ve výsledku přijmete ještě více kalorií, než
                kdybyste si dali trochu cukru.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Víte, jak rozpoznat{' '}
                  <a
                    href="https://nutritionpro.cz/blog/jak-rozpoznat-vyzivovy-mytus/"
                    target="_blank"
                  >
                    výživový mýtus
                  </a>
                  ?
                </i>
              </p>

              <h5 className={styles.postTitle}>Tak čím tedy sladit?</h5>
              <p className={styles.postText}>
                Nejvhodnější variantou z výše zmíněných sirupů je{' '}
                <b>čekankový sirup</b>, kterým si můžete například{' '}
                <b>zpestřit snídani</b> nebo osladit kávu. Jedná se o sirup,
                který můžete používat <b>pravidelně</b>, jen musíte nejprve
                zjistit, jestli vám dělá jeho konzumace dobře. Čas od času
                můžete samozřejmě použít i <b>obyčejný cukr</b> nebo{' '}
                <b>kterýkoliv sirup</b>, co máte rádi.
              </p>

              <p className={styles.postText}>
                Pokud se ale snažíte zhubnout nebo si obecně hlídat kalorický
                příjem, <b>nesnažte se hledat náhražky cukru</b> a spíše se
                zamyslete nad tím, <b>jak často</b> pokrmy či nápoje{' '}
                <b>doslazujete</b> nebo v čem všem, co konzumujete,{' '}
                <b>je cukr přidaný</b>. Tyto slazené potraviny se snažte{' '}
                <b>eliminovat</b>. Mohou to být například mléčné výrobky, proto
                si místo ovocného jogurtu kupte příště bílý a ovoce si do něj
                přidejte až doma. Také <b>čtěte složení výrobků</b> a
                kontrolujte si, <b>kolik cukru obsahují</b>. Snažte se také méně
                sladit a spíše si zvykat na nesladkou chuť. Ve zkratce -{' '}
                <b>nesnažte se cukr nahrazovat, ale spíše ubrat.</b>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default ChceteUdrzetVahuKrabickovouDietu
